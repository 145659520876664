import { useState, useEffect, useCallback } from "react";
import { useLocation, useParams } from "react-router-dom";

import useAxios from "../../hooks/useAxios";
import useErrorHandler from "../../hooks/useErrorHandler";
import {
  filterArrayDupsByUniqueElementValue,
  roundNumber,
  findArrayKeyByPropertyValue,
  sortArrayByPropertyValuesOrder
} from "../../helpers/functions";

import { AuthConsumer } from "../../contexts/AuthProvider";

const useAccount = () => {
  const errorHandler = useErrorHandler();
  const location = useLocation();
  const { accountId } = useParams();
  const { initAxios } = useAxios();
  const auth = AuthConsumer();

  const [state, setState] = useState({ loaded: false });

  const getPositionsAndRebates = transactions => {
    const positionsAndRebates = {
      positions: 0,
      rebates: 0
    };
    transactions.forEach(transaction => {
      // positions
      if (transaction.TTransaction && transaction.TTransaction !== "0") {
        positionsAndRebates.positions =
          transaction.TTransaction === "2"
            ? positionsAndRebates.positions -
              Number(transaction.TotalAmountBase)
            : positionsAndRebates.positions +
              Number(transaction.TotalAmountBase);
      }
      // rebates
      if (
        transaction.TTypeDisplayName === "Förvaltningsrabatt" ||
        transaction.TTypeDisplayName === "Ersättning Rabattavgift"
      ) {
        positionsAndRebates.rebates =
          positionsAndRebates.rebates + Number(transaction.TotalAmountBase);
      }
    });

    return positionsAndRebates;
  };

  const handleTransactionDownload = async (event, transaction) => {
    event.preventDefault;
    const { axiosInstance } = initAxios("private");

    transaction.OwnerName = auth.user.Name;
    transaction.OwnerId = auth.user.IdentityNo;

    // get document key in state.documents array
    const index = findArrayKeyByPropertyValue(
      state.transactions,
      "TransactionId",
      transaction.TransactionId
    );

    // set new document states
    state.transactions[index].Downloaded = false;
    state.transactions[index].Downloading = true;
    setState(prevState => ({
      ...prevState,
      transactions: state.transactions
    }));

    try {
      const response = await axiosInstance.post(
        "/documents/download/transaction",
        transaction,
        {
          responseType: "arraybuffer"
        }
      );

      const file = new Blob([response.data], { type: "application/pdf" });
      const fileURL = URL.createObjectURL(file);
      const link = document.createElement("a");
      link.href = fileURL;
      link.download = "avräkningsnota.pdf";
      link.click();

      // update document states
      state.transactions[index].Downloading = false;
      state.transactions[index].Downloaded = true;
      setState(prevState => ({
        ...prevState,
        transactions: state.transactions
      }));
    } catch (err) {
      errorHandler.serverError(err);
    }
  };

  const handleTransactionClick = TransactionId => {
    const { transactions } = state;
    // find array index by object prop value
    const logIndex = transactions.findIndex(object => {
      return object["TransactionId"] === TransactionId;
    });

    let updatedData = [...transactions]; // copy prev state data array
    updatedData[logIndex]["toggled"] = !transactions[logIndex].toggled;
    setState(prevState => ({
      ...prevState,
      transactions: updatedData
    }));
  };

  const fetchAccountData = useCallback(
    async axios => {
      const requests = [];

      try {
        // get Account details
        const accDetails = await axios.get(`/account/details/${accountId}`);

        const accDetailsData = accDetails.data;
        const { BenchmarkId, IsSpecial } = accDetailsData;

        // 0. Account summary holdings data request (Currency, Share, ETF)
        const requestSummaryHoldings = axios.get(
          `/account/summary/holdings/${accountId}`
        );
        requests.push(requestSummaryHoldings);

        // 1. Account all holdings
        const requestHoldings = axios.get(`/account/holdings/${accountId}`);
        requests.push(requestHoldings);

        // 2. Account deposits data request
        const requestDeposits = axios.get(`/account/deposits/${accountId}`);
        requests.push(requestDeposits);

        // 3. Account transactions data request
        const requestTransactions = axios.get(
          `/account/transactions/${accountId}`
        );
        requests.push(requestTransactions);

        // 4. Account highwatermark data request
        const requestHighWaterMark = axios.get(
          `/account/highwatermark/${accountId}`
        );
        requests.push(requestHighWaterMark);

        // Charts data requests
        const startDate = "1970-01-01";
        const endDate = "2050-01-01";

        // 5. Chart data request
        if (accDetailsData.ModelPortfolioId && IsSpecial === "0") {
          const requestChartData = axios.get(
            `/performance/data/${startDate}/${endDate}/${accountId}`
          );
          requests.push(requestChartData);
        }

        if (BenchmarkId && IsSpecial === "0") {
          // 6. Benchmark Chart data request
          // We always request the same defaultbenchmark if the benchmark criteria matches but this might change in the future
          const requestBenchmarkChartData = axios.get(
            `/performance/data/${startDate}/${endDate}/${BenchmarkId}/defaultbenchmark`
          );
          requests.push(requestBenchmarkChartData);
        }

        Promise.all(requests)
          .then(responses => {
            const summaryHoldings = responses[0].data;
            const holdings = responses[1].data;
            const totalDeposits = responses[2].data;
            const transactions = responses[3].data;
            const highWaterMark = responses[4].data;
            const chartData = IsSpecial === "0" ? responses[5].data : {};
            let benchmarkChartData =
              BenchmarkId && IsSpecial === "0" ? responses[6].data : {};

            if (benchmarkChartData.status === "error") {
              // if benchmarkChartData has error status set it to empty array (fallback for when BenchmarkId references a non existing account)
              console.log(benchmarkChartData.msg);
              benchmarkChartData = {};
            }

            const totalMarketValue =
              summaryHoldings.find(
                holding => holding.instrumentType === "TotalMarketValue"
              ) || {};
            // Format TotalMarketValue or if is not returned as a holding set its value to "0"
            totalMarketValue.value =
              totalMarketValue?.value?.toFixed(2).toString() || "0";

            const positionsAndRebates = getPositionsAndRebates(transactions);

            const totalReturns = {
              instrumentType: "TotalReturns",
              instrumentTypeLabel: "Total avkastning",
              value:
                Number(totalMarketValue.value) -
                (Number(totalDeposits) +
                  Number(positionsAndRebates.positions) +
                  Number(positionsAndRebates.rebates))
            };

            // sort summaryHoldings
            const sortedSummaryHoldings = sortArrayByPropertyValuesOrder(
              summaryHoldings,
              "instrumentType",
              [
                //sort order
                "Currency",
                "Share",
                "ETF",
                "Fund",
                "Warrant",
                "Bond",
                "Receivable",
                "Other"
              ]
            );

            let holdsTtlFifoAmntDirtyBase = 0;
            holdings.forEach(holding => {
              holdsTtlFifoAmntDirtyBase =
                holdsTtlFifoAmntDirtyBase + Number(holding.FifoAmountDirtyBase);
            });

            let transactionsChartData = [];
            // if no ModelPortfolioId or benchmark is special get transactionsChartData
            if (!accDetailsData.ModelPortfolioId || IsSpecial === "1") {
              // filter transactions by transaction type
              const chartTransactions = transactions.filter(
                transaction =>
                  transaction.TTypeName === "Buy" ||
                  transaction.TTypeName === "Subscription" ||
                  transaction.TTypeName === "Sell" ||
                  transaction.TTypeName === "Redemption"
              );
              // sort transactions by date asc
              chartTransactions.sort(
                (objA, objB) =>
                  Number(new Date(objA.TransactionDate)) -
                  Number(new Date(objB.TransactionDate))
              );
              // populate transactionsChartData
              chartTransactions.forEach((transaction, index) => {
                let value = 0;

                if (
                  transaction.TTypeName === "Buy" ||
                  transaction.TTypeName === "Subscription"
                ) {
                  value =
                    index > 0
                      ? roundNumber(transactionsChartData[index - 1][1], 2) +
                        roundNumber(transaction.TotalAmount, 2)
                      : roundNumber(transaction.TotalAmount, 2);
                } else if (
                  transaction.TTypeName === "Sell" ||
                  transaction.TTypeName === "Redemption"
                ) {
                  value =
                    index > 0
                      ? roundNumber(transactionsChartData[index - 1][1], 2) -
                        roundNumber(transaction.TotalAmount, 2)
                      : -roundNumber(transaction.TotalAmount, 2);
                }

                transactionsChartData.push([
                  Date.parse(transaction.TransactionDate), // date timestamp
                  value
                ]);
                // Also add today's date with last value
                if (index === chartTransactions.length - 1) {
                  transactionsChartData.push([
                    Date.parse(new Date()), // date timestamp
                    value
                  ]);
                }
              });

              // filter duplicate dates
              transactionsChartData = filterArrayDupsByUniqueElementValue(
                transactionsChartData,
                0
              );
            }

            setState(prevState => ({
              ...prevState,
              loaded: true,
              accDetails: accDetailsData,
              summaryData: {
                chartData,
                benchmarkChartData,
                transactionsChartData,
                hideDeposit: accDetailsData.HideDeposit === "1",
                summaryHoldings: sortedSummaryHoldings,
                holdings,
                holdsTtlFifoAmntDirtyBase,
                totalMarketValue,
                totalDeposits,
                totalReturns,
                positionsAndRebates,
                highWaterMark
              },
              transactions
            }));
          })
          .catch(function (err) {
            errorHandler.serverError(err);
          });
      } catch (err) {
        errorHandler.serverError(err);
      }
    },
    [accountId, errorHandler]
  );

  useEffect(() => {
    const { axiosInstance, axiosController } = initAxios("private");
    if (!state.loaded) {
      fetchAccountData(axiosInstance);
    }
    return () => axiosController.abort();
  }, [fetchAccountData, initAxios, state.loaded]);

  useEffect(() => {
    setState(prevState => ({
      ...prevState,
      loaded: false
    }));
  }, [location.pathname]);

  return {
    state,
    handleTransactionClick,
    handleTransactionDownload
  };
};

export default useAccount;
