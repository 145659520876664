import React, { useState, useEffect, useCallback, useRef } from "react";
import { useLocation } from "react-router-dom";
import Highcharts from "highcharts/highstock";
import HighchartsReact from "highcharts-react-official";
import CircularProgress from "@mui/material/CircularProgress";
import PropTypes from "prop-types";

import { format } from "date-fns";
import { findInArrayByIndexValue } from "../../../helpers/functions";

import useResponsive from "../../../hooks/useResponsive";
import useAxios from "../../../hooks/useAxios";
import useErrorHandler from "../../../hooks/useErrorHandler";

//TODO: separate concerns with a hook

const PerformanceChart = props => {
  const location = useLocation();
  const isDesktop = useResponsive("up", "md");
  const isLargeDesktop = useResponsive("up", "xl");
  const { initAxios } = useAxios();
  const axiosGlobalController = useRef(null);
  const errorHandler = useErrorHandler();

  const { chartData, benchmarkChartData, accountId, benchmarkId } = props;
  const [state, setState] = useState({ loaded: false });
  const accountData = useRef([]);
  const benchmarkData = useRef([]);
  const selectedRange = useRef(4);
  const flagDate = useRef(null);

  const hasSpecialAccounts = chartData.hasSpecialAccounts;
  const showBenchmarkChart =
    benchmarkId && benchmarkChartData.main?.length ? true : false;

  const findDataByDate = (data, targetDate) => {
    // Loop through the data array
    for (var i = 0; i < data.length; i++) {
      // Check if the x value (timestamp) matches the target date
      if (data[i][0] === targetDate) {
        // Return the matching element
        return data[i];
      }
    }
    // If no matching element is found, return null
    return null;
  };

  const onChartLoad = function () {
    const chart = this;
    if (showBenchmarkChart) {
      chart.series.forEach(serie => {
        const serieId = serie.userOptions.id;
        if (
          serieId !== "account" &&
          serieId !== "marketValue" &&
          serieId !== "highcharts-navigator-series"
        ) {
          // hide all series (redraw = false) execept account and navigator series
          serie.setVisible(false, false);
        }
      });
      chart.series[0].setData(accountData.current); // fix yAxis extremes for series[0]
      // // Select YTD view, will trigger afterSetExtremes to fetch performance data for this range
      // chart.rangeSelector.clickButton(5, true);
      // selectedRange.current = 5;
    }

    // fix navigator series data on first load
    chart.navigator.series[0].setData(accountData.current);
  };

  const afterSetExtremes = useCallback(
    function (e) {
      // console.log(`setExtremes triggered by ${e.trigger}`);
      if (e.trigger == undefined) return;
      const { chart } = e.target;
      chart.showLoading("Laddar…");

      const startDate = e.min
        ? format(new Date(e.min), "yyyy-MM-dd")
        : format(new Date("2000/01/01"), "yyyy-MM-dd");
      const endDate = e.max
        ? format(new Date(e.max), "yyyy-MM-dd")
        : format(new Date(), "yyyy-MM-dd");

      const requests = [];

      const { axiosInstance, axiosController } = initAxios("private");
      axiosGlobalController.current = axiosController;

      if (accountId) {
        const chartData = axiosInstance.get(
          `/performance/data/${startDate}/${endDate}/${accountId}`
        );
        requests.push(chartData);

        if (showBenchmarkChart) {
          const benchmarkChartData = axiosInstance.get(
            `/performance/data/${startDate}/${endDate}/${benchmarkId}/defaultbenchmark`
          );
          requests.push(benchmarkChartData);
        }
      } else {
        const chartData = axiosInstance.get(
          `/performance/data/${startDate}/${endDate}`
        );

        requests.push(chartData);
      }

      Promise.all(requests)
        .then(responses => {
          if (chart.series) chart.series[0].setData(responses[0].data.main);

          // also set the current options series (re-render fix)
          options.current.series[0].data = responses[0].data.main;
          if (!hasSpecialAccounts)
            options.current.series[1].data = responses[0].data.marketValue;

          if (showBenchmarkChart && chart.series) {
            chart.series[2].setData(responses[1].data.main);
            options.current.series[2].data = responses[1].data.main;

            if (responses[0].data.main?.length) {
              // set the flag series data to the benchmarkData element where the date matches the start date of accountData
              const flagCoords = findDataByDate(
                responses[1].data.main,
                flagDate.current
              );

              if (flagCoords?.length > 0) {
                options.current.series[3].data[0].x = flagCoords[0];
                options.current.series[3].data[0].y = flagCoords[1];
                chart.series[3].setData(options.current.series[3].data);
              }
            }
          }

          chart.hideLoading();
        })
        .catch(function (err) {
          errorHandler.serverError(err);
        });
    },
    [
      initAxios,
      accountId,
      showBenchmarkChart,
      benchmarkId,
      hasSpecialAccounts,
      errorHandler
    ]
  );

  const options = useRef({
    credits: {
      enabled: false
    },
    chart: {
      height: 400,
      // width: 550,
      events: {
        load: onChartLoad
      },
      position: {
        y: -15
      }
      // animation: false
    },

    // title: {
    //   text: "My chart"
    // },
    // subtitle: {
    //   text: "Click small/large buttons or change window size to test responsiveness"
    // },

    rangeSelector: {
      dropdown: isLargeDesktop ? "responsive" : "always",
      labelStyle: {
        display: "none"
      },
      buttonPosition: {
        align: "right",
        x: 0,
        y: 0
      },
      buttonTheme: {
        // styles for the buttons
        fill: "none",
        stroke: "none",
        "stroke-width": 1,
        width: 40,
        r: 1, // button border radius
        style: {
          color: "#4885B2",
          borderRadius: 0
        },
        states: {
          hover: {
            fill: "none",
            style: {
              fontWeight: "bold"
            }
          },
          select: {
            fill: "none",
            style: {
              color: "#2E457E",
              fontWeight: "bold"
            }
          }
          // disabled: { ... }
        }
      },
      buttons: [
        {
          type: "month",
          count: 1,
          text: "1 mån",
          title: "1 mån",
          events: {
            click: function () {
              selectedRange.current = 0;
            }
          }
        },
        {
          type: "month",
          count: 3,
          text: "3 mån",
          title: "3 mån",
          events: {
            click: function () {
              selectedRange.current = 1;
            }
          }
        },
        {
          type: "month",
          count: 6,
          text: "6 mån",
          title: "6 mån",
          events: {
            click: function () {
              selectedRange.current = 2;
            }
          }
        },
        {
          type: "year",
          count: 1,
          text: "1 år",
          title: "1 år",
          events: {
            click: function () {
              selectedRange.current = 3;
            }
          }
        },
        {
          type: "all",
          text: "Allt",
          title: "Allt",
          events: {
            click: function () {
              selectedRange.current = 4;
            }
          }
        },
        {
          type: "ytd",
          text: "YTD",
          title: "YTD",
          events: {
            click: function () {
              selectedRange.current = 5;
            }
          },

          preserveDataGrouping: true
        }
      ],
      inputEnabled: false, // it supports only days
      selected: 4 // index of which button will be selected at start i.e. YTD
    },

    xAxis: {
      type: "datetime",
      // labels: {
      //   format: "{value:%d-%m-%Y}"
      // },
      events: {
        afterSetExtremes: afterSetExtremes
      },
      minRange: 3600 * 1000 * 24 // (one day) minRange affects rangeSelector buttons, per example month will be disabled if minRange > 1 month, see https://api.highcharts.com/highstock/xAxis.minRange
    },

    yAxis: [
      {
        opposite: false, // Highstock sets the Y axis placement to opposite:true by default !!
        minRange: 20,
        ...(!hasSpecialAccounts && {
          height: "75%"
        })
        // min: yAxisMin,
        // max: yAxisMax,
        // startOnTick: false,
        // tickInterval: 15,
        // title: {
        //   text: "Avkastning  (%)"
        // }
      },
      ...(!hasSpecialAccounts
        ? [
            {
              title: {
                text: ""
              },
              labels: {
                enabled: false
              },
              minRange: 1000,
              top: "75%",
              height: "25%",
              offset: 0,
              lineWidth: 0
            }
          ]
        : [])
    ],

    series: !showBenchmarkChart
      ? [
          {
            // lineWidth: 3,
            id: "account",
            name: "Utveckling",
            color: "#4885B2",
            legendIndex: 1,
            showInLegend: showBenchmarkChart ? true : false,
            data: [],
            dataGrouping: {
              enabled: false
            },
            tooltip: {
              pointFormat: `<span style="color:{series.color}">{series.name}</span>: {point.y} %`,
              valueDecimals: 2
            }
          },
          ...(!hasSpecialAccounts
            ? [
                {
                  id: "marketValue",
                  type: "column",
                  name: "Marknadsvärde",
                  color: "#FFD5A3",
                  showInLegend: false,
                  data: [],
                  yAxis: 1,
                  dataGrouping: {
                    enabled: false
                  },
                  tooltip: {
                    pointFormat: `<span style="color:#ffaf4d">{series.name}</span>: {point.y} SEK`,
                    valueDecimals: 0
                  }
                }
              ]
            : [])
        ]
      : [
          {
            // lineWidth: 3,
            id: "account",
            name: "Utveckling",
            color: "#4885B2",
            legendIndex: 1,
            data: [],
            dataGrouping: {
              enabled: false
            },
            tooltip: {
              pointFormat: `<span style="color:{series.color}">{series.name}</span>: {point.y} %`,
              valueDecimals: 2
            }
          },
          {
            id: "marketValue",
            type: "column",
            name: "Marknadsvärde",
            color: "#FFD5A3",
            showInLegend: false,
            data: [],
            yAxis: 1,
            dataGrouping: {
              enabled: false
            },
            tooltip: {
              pointFormat: `<span style="color:#ffaf4d">{series.name}</span>: {point.y} SEK`,
              valueDecimals: 0
            }
          },
          {
            id: "benchmark",
            type: "spline",
            name: "Modellportfölj",
            color: "#FFD5A3",
            legendIndex: 2,
            data: [],
            dataGrouping: {
              enabled: true
            },
            tooltip: {
              pointFormat: `<span style="color:#ffaf4d">{series.name}</span>: {point.y} %`,
              valueDecimals: 2
            }
          },
          {
            id: "flag",
            type: "flags",
            name: "Förvaltningens start",
            showInLegend: false,
            y: -30, // flag pin length
            data: [
              {
                x: 0,
                y: 0,
                title: "Förvaltningens start"
              }
            ],
            tooltip: {
              valueDecimals: 2
            }
            // shape: "squarepin"
          }
        ],

    tooltip: {
      xDateFormat: "%Y-%m-%d"
    },

    navigator: {
      adaptToUpdatedData: false, // stops looping and disabling range selector buttons
      handles: { enabled: isDesktop },
      height: isDesktop ? 40 : 0,
      margin: isDesktop ? 25 : 0,
      maskInside: isDesktop,
      outlineWidth: isDesktop ? 1 : 0,
      series: {
        data: [],
        color: "#4885B2",
        fillOpacity: 0.3
      },
      xAxis: {
        visible: isDesktop
      },
      yAxis: {
        visible: isDesktop,
        minRange: 20
        // plotBands: [
        //   {
        //     color: "lightgrey",
        //     from: -100,
        //     to: 100
        //   }
        // ]
      }
    },

    scrollbar: {
      enabled: false
    },

    legend: showBenchmarkChart
      ? {
          enabled: true,
          borderRadius: 0,
          borderWidth: 0,
          floating: false,
          align: "right",
          verticalAlign: "top",
          layout: "horizontal",
          itemStyle: {
            color: "#262626"
          },
          itemHoverStyle: {
            color: "#808080"
          },
          itemHiddenStyle: {
            color: "#cccccc"
          }
        }
      : {},

    plotOptions: {
      series: {
        // stacking: "normal"
        events: {
          legendItemClick: function (e) {
            e.preventDefault();
            const thisSeries = this,
              thisSeriesId = thisSeries.userOptions.id,
              chart = this.chart;

            if (thisSeries.visible === true) return;

            thisSeries.show();
            chart.series.forEach(serie => {
              const serieId = serie.userOptions.id;

              // show marketValue chart if we switch to "Utveckling" chart
              if (serieId === "marketValue" && thisSeriesId === "account") {
                return serie.show();
              }

              // show flag if we switch to "Modellportfölj" chart
              if (serieId === "flag" && thisSeriesId === "benchmark") {
                return serie.show();
              }

              if (serie !== thisSeries) {
                serie.hide();
              }
            });

            chart.navigator.series[0].show();
            if (thisSeriesId === "benchmark") {
              chart.yAxis[0].update({
                height: "100%"
              });
              chart.yAxis[1].update({
                height: "0%"
              });
              chart.navigator.series[0].setData(benchmarkData.current);
            } else {
              chart.yAxis[0].update({
                height: "75%"
              });
              chart.yAxis[1].update({
                height: "25%"
              });
              chart.navigator.series[0].setData(accountData.current);
            }
            // set range selector to currently selected range
            chart.rangeSelector.clickButton(selectedRange.current, true);
          }
        }
      }
    }

    // responsive: {
    //   // creates loop !!??
    //   rules: [
    //     {
    //       condition: {
    //         maxWidth: 300 // this is relative to chart width not a breakpoint!? Breaks chart, handle with care..
    //       },
    //       chartOptions: {
    //         chart: {
    //           height: 300,
    //           width: null
    //         },
    //         subtitle: {
    //           text: null
    //         },
    //         navigator: {
    //           enabled: false,
    //           adaptToUpdatedData: false // stops looping and disabling range selector buttons
    //         },
    //         rangeSelector: {
    //           dropdown: "always"
    //         }
    //       }
    //     }
    //   ]
    // }
  });

  useEffect(() => {
    setState(prevState => ({
      ...prevState,
      loaded: false
    }));
  }, [location.pathname]);

  useEffect(() => {
    if (!state.loaded) {
      // console.log("fetching chart data");
      accountData.current = chartData.main;
      // set current chart series
      options.current.series[0].data = accountData.current;
      if (!hasSpecialAccounts)
        options.current.series[1].data = chartData.marketValue;
      // set current navigator series
      options.current.navigator.series.data = accountData.current;

      if (showBenchmarkChart) {
        benchmarkData.current = benchmarkChartData.main;
        // set benchmark chart series
        options.current.series[2].data = benchmarkData.current;

        if (accountData.current?.length) {
          // set the flag series data to the benchmarkData element where the date matches the start date of accountData
          const accountDataStartDate = accountData.current[0][0];
          const flagCoords = findInArrayByIndexValue(
            benchmarkData.current,
            0,
            accountDataStartDate
          );
          if (flagCoords.length > 0) {
            flagDate.current = flagCoords[0][0];
            options.current.series[3].data[0].x = flagCoords[0][0];
            options.current.series[3].data[0].y = flagCoords[0][1];
          }
        }
      }

      setState({ loaded: true });
    }

    return () => axiosGlobalController.current?.abort();
  }, [
    state.loaded,
    chartData,
    benchmarkChartData,
    showBenchmarkChart,
    hasSpecialAccounts
  ]);

  return !state.loaded ? (
    <CircularProgress />
  ) : (
    <HighchartsReact
      highcharts={Highcharts}
      allowChartUpdate={false}
      constructorType={"stockChart"}
      options={options.current}
      containerProps={{
        style: {
          position: "relative",
          top: "-35px"
        }
      }}
    />
  );
};

export default PerformanceChart;

PerformanceChart.propTypes = {
  chartData: PropTypes.object,
  benchmarkChartData: PropTypes.object,
  accountId: PropTypes.string,
  benchmarkId: PropTypes.string
};
