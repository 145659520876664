import React, { useState, useCallback } from "react";
import { format } from "date-fns";
import PropTypes from "prop-types";
import { CSVLink } from "react-csv";

import Container from "@mui/material/Container";
import Box from "@mui/material/Box";
import Table from "@mui/material/Table";
import TableRow from "@mui/material/TableRow";
import TableFooter from "@mui/material/TableFooter";
import TablePagination from "@mui/material/TablePagination";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import Link from "@mui/material/Link";

import TablePaginationActions from "./TablePaginationActions";

import {
  getCookie,
  setCookie,
  convertAmount,
  convertToCurrency
} from "../../helpers/functions";
import exportToExcel from "../../helpers/exportToExcel";

const CustomTableFooter = props => {
  const { type, data, page, rowsPerPage, setRowsPerPage, handleChangePage } =
    props;

  const [rowsPerPageSelect, setRowsPerPageSelect] = useState(rowsPerPage);
  const rowsPerPageOptions = {
    10: 10,
    20: 20,
    50: 50,
    Allt: -1
  };

  let exportData = [];
  switch (type) {
    case "transactions":
      exportData = data.map(item => {
        return {
          Datum: item.TransactionDate,
          Konto: item.AccountNo,
          Typ: item.TypeLabel,
          Instrument: item.Name,
          Antal: item.Count > 0 ? convertAmount(item.Count) : "-",
          Kurs:
            item.Price && item.Price !== "0,00"
              ? convertToCurrency(item.Price) + " " + item.CurrencyCode
              : "-",
          Belopp: convertToCurrency(item.TotalAmount)
        };
      });
      break;
    case "logs":
      exportData = data.map(item => {
        return {
          Datum: item.Created,
          Användare: item.Name,
          "Agerar som":
            item.ActingAsName !== item.Name ? item.ActingAsName : "-",
          Handling: item.Action,
          "IP Address": item.IpNumber,
          UserAgent: item.UserAgent,
          Data: item.Data
        };
      });
      break;
    case "poas":
      exportData = data.map(item => {
        return {
          Id: item.PoaId,
          Fullmaktstagare: item.AgentName,
          "Fullmaktstagare Personnummer": item.AgentIdentityNo,
          Fullmaktsgivare: item.PrincipalName,
          "Fullmaktsgivare Personnummer": item.PrincipalIdentityNo,
          Fullmaktstyp: item.Type === "View" ? "Insyn" : item.Type,
          Källa: item.IsManual > 0 ? "Manual" : "CrossKey",
          Startdatum:
            item.StartDate > 0
              ? format(new Date(item.StartDate), "yyyy-MM-dd")
              : "-",
          EndDate:
            item.EndDate > 0
              ? format(new Date(item.EndDate), "yyyy-MM-dd")
              : "-",
          Kommentarer: item?.Comment || "-"
        };
      });
      break;
    default:
      exportData = [];
      break;
  }

  const handleRowsPerPageChange = useCallback(
    event => {
      const dashboardOptions = JSON.parse(getCookie("fairOptions")) || {};
      setRowsPerPage(event.target.value);
      setRowsPerPageSelect(event.target.value);
      // set options cookie as per footer type
      return setCookie(
        "fairOptions",
        JSON.stringify({
          rowsPerPage: {
            ...dashboardOptions?.rowsPerPage,
            [type]: event.target.value
          }
        })
      );
    },
    [setRowsPerPage, type]
  );

  const getPageCountDisplayText = () => {
    switch (type) {
      case "messages":
        return (
          <>
            Hittade {data.length}{" "}
            {data.length === 1 ? "meddelande" : "meddelanden"}
          </>
        );
      case "transactions":
        return <>Hittade {data.length} transaktioner</>;
      case "holdings":
        return <>Hittade {data.length} innehav</>;
      case "reports":
        return <>Hittade {data.length} dokument</>;
      case "poas":
        return <>Hittade {data.length} fullmaktstagare</>;
      case "logs":
        return <>Hittade {data.length} loggar</>;
      case "recipientsRead":
        if (data.length === 0) {
          return <>Ingen har läst meddelandet ännu</>;
        }
        return (
          <>
            Hittade {data.length} {data.length === 1 ? "kund" : "kunder"}
          </>
        );
      case "customers":
        return (
          <>
            Hittade {data.length} {data.length === 1 ? "kund" : "kunder"}
          </>
        );
      case "backofficeusers":
        return (
          <>
            Hittade {data.length}{" "}
            {data.length === 1 ? "backofficeanvändare" : "backofficeanvändare"}
          </>
        );
      default:
        break;
    }
  };

  const pageCountDisplayText = getPageCountDisplayText();

  return (
    <Container
      sx={{
        maxWidth: "none !important",
        pl: "0 !important",
        pr: "0 !important",
        flexDirection: "column",
        alignItems: "unset",
        mt: 0,
        mb: { xs: 2.5, md: 0 },
        mx: 0,
        borderTop: data.length > 0 ? 1 : 0,
        borderColor: "#F1F3F4"
      }}
    >
      <Box
        sx={{
          mt: 2.5,
          mb: 0,
          mx: 0,
          fontSize: "0.875rem"
        }}
      >
        <b style={{ marginRight: 10 }}>{pageCountDisplayText}</b>
        {setRowsPerPage && data.length > rowsPerPage && (
          <>
            <span>Sidstorlek: </span>
            <FormControl
              variant="standard"
              sx={{
                m: 0,
                width: 55,
                verticalAlign: "unset"
              }}
            >
              <Select
                disableUnderline
                size="small"
                labelId="account-select-label"
                id="account-select"
                value={rowsPerPageSelect}
                onChange={handleRowsPerPageChange}
                sx={{
                  fontSize: "0.8rem",
                  height: 20,
                  zIndex: 0,
                  "& .MuiInputBase-input": {
                    padding: 0,
                    textAlign: "center",
                    "&:focus": {
                      backgroundColor: "transparent"
                    }
                  },
                  "& .MuiSelect-iconStandard": {
                    fontSize: 25,
                    top: "calc(45% - 0.5em)"
                  }
                }}
              >
                {Object.entries(rowsPerPageOptions).map(
                  ([key, option]) =>
                    option < data.length && (
                      <MenuItem
                        key={option}
                        value={option}
                        sx={{
                          fontSize: "0.8rem"
                        }}
                      >
                        {key}
                      </MenuItem>
                    )
                )}
              </Select>
            </FormControl>
          </>
        )}

        {exportData.length > 0 && (
          <>
            <span> Exportera: </span>
            <CSVLink
              data={exportData}
              separator={","}
              enclosingCharacter={`"`}
              filename={"Loggar.csv"}
              style={{ color: "#2E457E" }}
            >
              <span>CSV</span>
            </CSVLink>
            <span> / </span>
            <Link
              sx={{ cursor: "pointer" }}
              onClick={() => exportToExcel(exportData, "Loggar.xlsx")}
            >
              <span>XLSX</span>
            </Link>
          </>
        )}
      </Box>
      {data.length > rowsPerPage && (
        <Table>
          <TableFooter>
            <TableRow>
              <TablePagination
                // colSpan={7}
                count={data.length}
                rowsPerPage={rowsPerPage}
                rowsPerPageOptions={[rowsPerPage]}
                page={page}
                onPageChange={handleChangePage}
                ActionsComponent={TablePaginationActions}
                sx={{
                  border: 0,
                  fontSize: "0.8rem",
                  ".MuiTablePagination-spacer": {
                    display: "none"
                  },
                  ".MuiToolbar-root": {
                    minHeight: "0",
                    px: 0
                  },
                  ".MuiTablePagination-displayedRows": {
                    flex: 1,
                    fontSize: "0.8rem",
                    color: "#666"
                  }
                }}
                labelDisplayedRows={({ from, to, count }) => {
                  return `Visar ${from} till ${to} av ${
                    count !== -1 ? count : `more than ${to}`
                  }`;
                }}
              />
            </TableRow>
          </TableFooter>
        </Table>
      )}
    </Container>
  );
};

CustomTableFooter.propTypes = {
  type: PropTypes.string.isRequired,
  data: PropTypes.array.isRequired,
  page: PropTypes.number,
  rowsPerPage: PropTypes.number,
  setRowsPerPage: PropTypes.func,
  handleChangePage: PropTypes.func.isRequired
};

export default CustomTableFooter;
