import { useState, useEffect, useCallback, useRef } from "react";

import useAxios from "../../hooks/useAxios";
import useErrorHandler from "../../hooks/useErrorHandler";

const useInsights = () => {
  const { initAxios } = useAxios();
  const axiosGlobalController = useRef(null);
  const errorHandler = useErrorHandler();
  const [state, setState] = useState({ loaded: false, activityInsights: [] });

  const fetchInsightsData = useCallback(async () => {
    const { axiosInstance, axiosController } = initAxios("private");
    axiosGlobalController.current = axiosController;

    try {
      // Account transactions data request
      const requestInsights = await axiosInstance.get(`/insights/activity`);

      setState(prevState => ({
        ...prevState,
        loaded: true,
        activityInsights: requestInsights.data
      }));
    } catch (err) {
      errorHandler.serverError(err);
    }
  }, [errorHandler, initAxios]);

  useEffect(() => {
    if (!state.loaded) {
      fetchInsightsData();
    }

    return () => axiosGlobalController.current?.abort();
  }, [fetchInsightsData, state.loaded]);

  return {
    state
  };
};

export default useInsights;
