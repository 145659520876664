import React from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { CircularProgress } from "@mui/material";
import CircleIcon from "@mui/icons-material/Circle";

import SectionHeaderPaper from "../../theme/styled/SectionHeaderPaper";
import SectionWrapperBox from "../../theme/styled/SectionWrapperBox";
import DashboardPaper from "../../theme/styled/DashboardPaper";

import useInsights from "./useInsights";
import InsightsChart from "./InsightsChart";

const CustomerInsightsSection = () => {
  const { state } = useInsights();

  return (
    <Box>
      <SectionHeaderPaper>
        <Typography variant="h3" align="left">
          Kundinsikt
        </Typography>
      </SectionHeaderPaper>
      <SectionWrapperBox sx={{ flexFlow: "column", mx: 2.5, mt: 5 }}>
        <DashboardPaper>
          <Box
            sx={{
              display: "flex",
              flexFlow: { xs: "column", lg: "row" }
            }}
          >
            <Box sx={{ width: { lg: "60%" } }}>
              <Typography
                variant="h6"
                align="left"
                sx={{
                  position: "relative",
                  mb: 4,
                  zIndex: 2
                }}
              >
                Aktivitetssegment per vecka
              </Typography>
              {!state.loaded ? (
                <Box
                  sx={{
                    height: "100%",
                    minHeight: "80vh",
                    width: "100%",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center"
                  }}
                >
                  <CircularProgress />
                </Box>
              ) : (
                <InsightsChart chartData={state.activityInsights} />
              )}
            </Box>
            <Box
              sx={{
                display: "flex",
                flexFlow: "column",
                width: { lg: "40%" },
                ml: { md: 3, lg: 5 }
              }}
            >
              <Typography
                variant="h6"
                align="left"
                sx={{
                  position: "relative",
                  mb: { xs: 1, md: 2 },
                  zIndex: 2
                }}
              >
                Förklaring
              </Typography>

              <Typography
                align="left"
                variant="body2"
                color="common.black"
                component="p"
                sx={{
                  mb: { xs: 1, md: 2 }
                }}
              >
                Segmenten räknas ut i slutet av varje vecka och inkluderar
                antalet inloggningar under rullande 30 dagar.
              </Typography>

              <Typography
                align="left"
                variant="body2"
                color="common.black"
                component="p"
                sx={{ mt: 1 }}
              >
                <CircleIcon
                  fontSize="small"
                  sx={{
                    color: "#999",
                    mb: -0.5,
                    mr: 1
                  }}
                />
                <span>Låg aktivitet = 1 - 2 inloggningar</span>
              </Typography>
              <Typography
                align="left"
                variant="body2"
                color="common.black"
                component="p"
                sx={{ mt: 1 }}
              >
                <CircleIcon
                  fontSize="small"
                  sx={{
                    color: "#f6b26b",
                    mb: -0.5,
                    mr: 1
                  }}
                />
                <span>Medium aktivitet = 3 - 7 inloggningar</span>
              </Typography>
              <Typography
                align="left"
                variant="body2"
                color="common.black"
                component="p"
                sx={{ mt: 1 }}
              >
                <CircleIcon
                  fontSize="small"
                  sx={{
                    color: "#67d4cb",
                    mb: -0.5,
                    mr: 1
                  }}
                />
                <span>Hög aktivitet = 8 - 29 inloggningar</span>
              </Typography>
              <Typography
                align="left"
                variant="body2"
                color="common.black"
                component="p"
                sx={{ mt: 1 }}
              >
                <CircleIcon
                  fontSize="small"
                  sx={{
                    color: "#1f9200",
                    mb: -0.5,
                    mr: 1
                  }}
                />
                <span>Väldigt hög aktivitet = 30 - 59 inloggningar</span>
              </Typography>
              <Typography
                align="left"
                variant="body2"
                color="common.black"
                component="p"
                sx={{ mt: 1 }}
              >
                <CircleIcon
                  fontSize="small"
                  sx={{
                    color: "#ce0000",
                    mb: -0.5,
                    mr: 1
                  }}
                />
                <span>Superanvändare = 60+ inloggningar</span>
              </Typography>
            </Box>
          </Box>
        </DashboardPaper>
      </SectionWrapperBox>
    </Box>
  );
};

export default CustomerInsightsSection;
