import React, { Fragment } from "react";
import PropTypes from "prop-types";

import { styled } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import InfoIcon from "@mui/icons-material/InfoOutlined";

import { format } from "date-fns";

import DashboardPaper from "../../theme/styled/DashboardPaper";

// import useSummary from "./useSummary";
import useResponsive from "../../hooks/useResponsive";
import PerformanceChart from "./PerformanceChart";
import TransactionsChart from "./TransactionsChart";
import HoldingsTable from "./HoldingsTable";

import { convertToCurrency } from "../../helpers/functions";
import { DashboardConsumer } from "../../contexts/DashboardProvider";

const HtmlTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: "#f5f5f9",
    color: "rgba(0, 0, 0, 0.87)",
    maxWidth: 220,
    fontSize: theme.typography.pxToRem(12),
    border: "1px solid #dadde9"
  }
}));

const Summary = props => {
  const isDesktop = useResponsive("up", "md");
  const dashboardCtxt = DashboardConsumer();
  const { summaryData, accountId, benchmarkId, modelPortfolioId, isSpecial } =
    props;

  const isAccount = location.pathname.includes("/dashboard/depaer/")
    ? true
    : false;

  const {
    chartData,
    benchmarkChartData,
    transactionsChartData,
    summaryHoldings,
    totalMarketValue,
    hideDeposit,
    totalDeposits,
    totalReturns,
    positionsAndRebates,
    holdings,
    holdsTtlFifoAmntDirtyBase,
    highWaterMark
  } = isAccount ? summaryData : dashboardCtxt.state;

  const totalCurrencyValue = summaryHoldings.find(
    holding => holding.instrumentType === "Currency"
  )?.value;

  return (
    <DashboardPaper>
      <Box
        sx={{
          display: "flex",
          flexFlow: { xs: "column", lg: "row" }
        }}
      >
        <Box sx={{ width: { lg: "60%" } }}>
          <Typography
            variant="h6"
            align="left"
            sx={{
              position: "relative",
              width: "30%",
              mb: 0.63,
              zIndex: 2
            }}
          >
            Utveckling
          </Typography>
          {!isAccount || (modelPortfolioId && isSpecial === "0") ? (
            <PerformanceChart
              chartData={chartData}
              benchmarkChartData={benchmarkChartData}
              accountId={accountId}
              benchmarkId={benchmarkId}
            />
          ) : (
            <TransactionsChart chartData={transactionsChartData} />
          )}
        </Box>
        <Box
          sx={{
            display: "flex",
            flexFlow: "column",
            width: { lg: "40%" },
            ml: { md: 2, lg: 3 }
          }}
        >
          <Box>
            <Typography
              variant="h6"
              align="left"
              sx={{
                mb: 2.5
              }}
            >
              Översikt
            </Typography>
            <Typography align="left" variant="body2" color="common.black">
              Likvider
              <span style={{ float: "right" }}>
                {(totalCurrencyValue &&
                  convertToCurrency(totalCurrencyValue)) ||
                  0}{" "}
                SEK
              </span>
            </Typography>

            {summaryHoldings.map((holding, key) => {
              if (
                holding.instrumentType !== "Currency" &&
                holding.instrumentType !== "TotalMarketValue" &&
                holding.instrumentType !== "TotalReturns" &&
                holding.value !== 0
              ) {
                return (
                  <Typography
                    key={key}
                    align="left"
                    variant="body2"
                    color="common.black"
                  >
                    {holding.instrumentTypeLabel}
                    <span style={{ float: "right" }}>
                      {(holding.value && convertToCurrency(holding.value)) || 0}{" "}
                      SEK
                    </span>
                  </Typography>
                );
              }
            })}

            <Typography
              align="left"
              variant="body2"
              color="common.black"
              component="p"
              sx={{ mt: 0, borderTop: 1 }}
            >
              <b>{totalMarketValue.instrumentTypeLabel}</b>
              <Typography
                variant="body2"
                sx={{ float: "right" }}
                component="span"
              >
                <b>
                  {(totalMarketValue.value &&
                    convertToCurrency(totalMarketValue.value)) ||
                    0}{" "}
                  SEK
                </b>
              </Typography>
            </Typography>
          </Box>
          {!hideDeposit && (
            <Fragment>
              <Box sx={{ mt: { xs: 2, md: 3 } }}>
                <Typography align="left" variant="body2" color="common.black">
                  Nettoinsättningar{" "}
                  <HtmlTooltip
                    disableFocusListener
                    placement={isDesktop ? "bottom" : "top"}
                    title={
                      <React.Fragment>
                        <Typography color="inherit" variant="body2">
                          Denna summa redovisar nettoinsättningar, det vill säga
                          dina insättningar minus eventuella uttag från depån
                        </Typography>
                      </React.Fragment>
                    }
                  >
                    <InfoIcon fontSize="inherit" />
                  </HtmlTooltip>
                  <span style={{ float: "right" }}>
                    {(totalDeposits > 0 && convertToCurrency(totalDeposits)) ||
                      0}{" "}
                    SEK
                  </span>
                </Typography>
              </Box>
              <Box>
                <Typography align="left" variant="body2" color="common.black">
                  Totala inflyttade tillgångar
                  <span style={{ float: "right" }}>
                    {positionsAndRebates.positions &&
                      convertToCurrency(positionsAndRebates.positions)}{" "}
                    SEK
                  </span>
                </Typography>
              </Box>
              <Box>
                <Typography align="left" variant="body2" color="common.black">
                  Total förvaltningsrabatt
                  <span style={{ float: "right" }}>
                    {positionsAndRebates.rebates &&
                      convertToCurrency(positionsAndRebates.rebates)}{" "}
                    SEK
                  </span>
                </Typography>
              </Box>
              <Box sx={{ mt: 1 }}>
                <Typography align="left" variant="body2" color="common.black">
                  <b>
                    {totalReturns.instrumentTypeLabel}{" "}
                    <HtmlTooltip
                      disableFocusListener
                      placement={isDesktop ? "bottom" : "top"}
                      title={
                        <React.Fragment>
                          <Typography color="inherit" variant="body2">
                            Det här är den totala avkastningen du fått på alla
                            dina innehav oavsett när du investerat. Den beräknas
                            som marknadsvärdet på dina tillgångar minus dina
                            nettoinsättningar efter eventuell
                            förvaltningsrabatt. Om du gjort flera
                            insättningar/uttag under årens lopp kan du därför
                            INTE använda denna siffra för att beräkna din
                            effektiva årliga avkastning.
                          </Typography>
                        </React.Fragment>
                      }
                    >
                      <InfoIcon fontSize="inherit" />
                    </HtmlTooltip>
                    <span style={{ float: "right" }}>
                      {totalReturns.value &&
                        convertToCurrency(totalReturns.value)}{" "}
                      SEK
                    </span>
                  </b>
                </Typography>
              </Box>
              {isAccount && highWaterMark && (
                <Box>
                  <Typography align="left" variant="body2" color="common.black">
                    Högvattenmärke{" "}
                    {format(new Date(highWaterMark.Date), "yyyy-MM-dd")}{" "}
                    <HtmlTooltip
                      disableFocusListener
                      placement={isDesktop ? "bottom" : "top"}
                      title={
                        <React.Fragment>
                          {/* <Typography color="inherit" variant="body2">
                        Det Högsta värdet för din depå{" "}
                        <span style={{ color: "green" }}>plus</span>{" "}
                        insättningar,{" "}
                        <span style={{ color: "red" }}>minus</span> avgifter,
                        skatt och uttag per redovisat datum.
                      </Typography> */}
                          <Typography color="inherit" variant="body2">
                            Högvattenmärket är det högsta värdet för din depå.
                            Värdet beräknas som den högsta punkten i en period
                            och regleras genom att periodens insättningar
                            adderas och periodens avgifter, skatter samt uttag
                            dras bort.
                          </Typography>
                        </React.Fragment>
                      }
                    >
                      <InfoIcon fontSize="inherit" />
                    </HtmlTooltip>
                    <span style={{ float: "right" }}>
                      {highWaterMark.HighWatermark &&
                        convertToCurrency(highWaterMark.HighWatermark)}{" "}
                      SEK
                    </span>
                  </Typography>
                </Box>
              )}
            </Fragment>
          )}
        </Box>
      </Box>
      {isAccount && (
        <Box sx={{ mt: { xs: 2, md: 3 } }}>
          <Typography
            variant="h6"
            sx={{
              mb: 2
            }}
          >
            Innehav
          </Typography>
          <HoldingsTable
            holdings={holdings}
            holdingsTotalMarketValue={totalMarketValue.value}
            holdsTtlFifoAmntDirtyBase={holdsTtlFifoAmntDirtyBase}
          />
        </Box>
      )}
    </DashboardPaper>
  );
};

Summary.propTypes = {
  summaryData: PropTypes.object,
  accountId: PropTypes.string,
  modelPortfolioId: PropTypes.string,
  benchmarkId: PropTypes.string,
  isSpecial: PropTypes.string
};

export default Summary;
